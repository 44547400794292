export function initMap(
  container: HTMLDivElement,
  locations: Record<string, number>[],
  marker: string
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  if (!window.google) return null;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const map = new google.maps.Map(container, {
    center: { lat: 55.749673, lng: 37.538221 },
    scrollwheel: true,
    zoom: 16,
    mapTypeControl: false,
  });

  locations.forEach((latlng) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line no-new
    new google.maps.Marker({
      icon: marker,
      map,
      position: latlng,
    });
  });

  return map;
}
