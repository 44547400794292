import React, { FC, useEffect, useRef } from 'react';
import { initMap } from '../../../utils/map';
import styles from './styles.module.scss';
import { usePathPrefix } from '../../../constants/hooks';

const LOCATIONS = {
  KAZAKHSTAN: { lat: 43.2408501, lng: 76.9149342 },
};
interface IProps {
  location: keyof typeof LOCATIONS;
}

const Map: FC<IProps> = ({ location = 'MOSCOW' }) => {
  const ref = useRef<HTMLDivElement>(null);
  const map = useRef<any>(null);
  const prefix = usePathPrefix();

  useEffect(() => {
    if (!ref.current) return;

    map.current = initMap(
      ref.current,
      Object.values(LOCATIONS),
      `${prefix}/contacts/marker.svg`
    );
  }, [ref.current]);

  useEffect(() => {
    map.current.setCenter(LOCATIONS.KAZAKHSTAN);
  }, [location, map.current]);
  return (
    <>
      <div ref={ref} className={styles.map} />
    </>
  );
};

export { Map };
