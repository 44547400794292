/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, {
  FC,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

interface IProps {
  selected?: boolean;
  onClick?: MouseEventHandler;
  icon: string;
  title: ReactNode;
  iconAlt: string;
}

const ContactsCard: FC<IProps> = ({
  selected,
  onClick,
  icon,
  iconAlt,
  title,
  children,
}) => {
  const [isNotActive, setNotActive] = useState(false);
  useEffect(() => {
    if (selected === false) {
      setNotActive(true);
    } else {
      setNotActive(false);
    }
  }, [selected]);

  return (
    <div
      className={classNames(styles.card, {
        [styles.card_selected]: selected,
        [styles.card_isNotActive]: isNotActive,
        [styles.card_clickable]: !!onClick,
      })}
      onClick={onClick}
    >
      <img src={icon} alt={iconAlt} className={styles.card__image} />

      <div className={styles.card__text}>
        <h4>{title}</h4>
        <span className={styles.address}>{children}</span>
      </div>
    </div>
  );
};

export default ContactsCard;
