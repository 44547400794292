/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';
import classnames from 'classnames';
import { t } from '../../../i18n';
import { Map } from '../Map';
import styles from './styles.module.scss';
import { CITIES } from '../../../constants/contacts';

interface IProps {
  tab: CITIES;
  setTab: (tab: CITIES) => void;
}

const ContactsMap: FC<IProps> = ({ tab, setTab }) => (
  <>
    <div className={styles.map__tabs}>
      <div
        className={classnames(styles.map__tab, {
          [styles.map__tab_active]: tab === CITIES.KAZAKHSTAN,
        })}
        onClick={() => setTab(CITIES.KAZAKHSTAN)}
      >
        {t('contacts.kazakhstan')}
      </div>
    </div>

    <div className={styles.map}>
      <Map location={tab} />
    </div>
  </>
);

export default ContactsMap;
